import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import ReactGA from 'react-ga'
import LogRocket from 'logrocket'
import _ from 'lodash'

import Footer from "./marketing/footer"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import "./global.scss"
// let window = typeof window !== 'undefined' && window
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      win: false
    };
  }

  componentDidMount() {
    //Include the Crisp code here, without the <script></script> tags
    // this.addSliderScript()
    if(process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA)
    }
    // if (window && !window.google) {
    //   (function () {
    //     var d = document;
    //     var s = d.createElement("script");
    //     s.type = "text/javascript"
    //     //s.src = "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places";
    //     s.src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD4fnXwBEkt6JBGv5G0Lt84mgO3z3FpvdQ&libraries=places"
    //     // d.getElementsByTagName.appendChild(s);
    //     d.getElementsByTagName("head")[0].appendChild(s);
    //     //d.getElementById("calendly1")[0].appendChild(s);
    //   })();
    // }
    let self = this
    setTimeout(function () {
      if (window) {
        self.setState({ win: true })
      }
    }, 1000);

  }

  addSliderScript() {
    var d = document;
    var s = d.createElement("script");
    s.type = "text/javascript"
    s.src="https://unpkg.com/flickity@2.2.1/dist/flickity.pkgd.min.js"
    d.getElementsByTagName("head")[0].appendChild(s);
  }
  render () {
    if (this.state.win) {
      if(window && window.location) {
        const logRocketAppName = _.get(process.env, 'REACT_APP_LOGROCKET', '')
       // LogRocket.init(logRocketAppName, {
         // rootHostname: window.location.origin
       // })
      }
    }
    const {children} = this.props
    let title = children[1] && children[1].props && children[1].props.pageContext ? children[1].props.pageContext.title : "Curbside Dumpster Delivery"
    if (this.state.win) {
      if (window && window.location && window.location.pathname) {
        if (window.location.pathname === "/business/" || window.location.pathname === "/business" ) {
          title = 'Debris Removal for New York Businesses | Curbside '
        }
        if (window.location.pathname === "/curbside-now/" || window.location.pathname === "/curbside-now" ) {
          title = 'New York Debris Removal | Curbside'
        }
        if (window.location.pathname === "/about/" || window.location.pathname === "/about" ) {
          title = 'About Curbside | Curbside'
        }
        if (window.location.pathname === "/curbside-now/faq/" || window.location.pathname === "/curbside-now/faq" ) {
          title = 'Frequently Asked Questions | Curbside'
        }
        if (window.location.pathname === "/legal/" || window.location.pathname === "/legal" ) {
          title = 'Terms | Curbside'
        }
      }
    }
    return(
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title,

            }
          }
        }
      `}
      render={data => (
        <>
        <Helmet
          title={ title }
          meta={[
            { name: 'title', content: children[1] && children[1].props && children[1].props.pageContext ? children[1].props.pageContext.title : 'Curbside Dumpster Delivery' },
            { name: 'description', content: children[1].props && children[1].props.pageContext ? children[1].props.pageContext.description : `The cheapest most affordable dumpster removal in New York` },
            { name: 'keywords', content: children[1].props && children[1].props.pageContext ? children[1].props.pageContext.keywords : `Tags: Dumper rental, same day removal, affordable dumpster removal, 10 yard container rental, 20 yard container rental, etc` },
            { name: 'url', 'content': children[1].props && children[1].props.pageContext ? children[1].props.pageContext.url : 'https://mycurbside.com/' },
            { property: 'og:title', 'content': children[1].props && children[1].props.pageContext ? children[1].props.pageContext.title : 'Curbside Dumpster Delivery' },
            { property: 'og:description', 'content': children[1].props && children[1].props.pageContext ? children[1].props.pageContext.description : `The cheapest most affordable dumpster removal in New York` },
            { property: 'og:url', 'content': children[1].props && children[1].props.pageContext ? children[1].props.pageContext.url : 'https://mycurbside.com/' },
            { property: 'og:site_name', 'content': 'Curbside Inc' },
            { property: 'og:image:width', 'content': '1024' },
            { property: 'og:image:height', 'content': '645' },
            { name: 'viewport', content:"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" }
          ]}
        >

          <html lang="en" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-116870437-1"></script>
          <script>
            { ` window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-116870437-1');`
          }
          </script>
          <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-58RR4QF');`}</script>

          {/*<script>
          { `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1574682622831165');
            fbq('track', 'PageView');`
          }
          </script>*/}
          <noscript>
            {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1574682622831165&ev=PageView&noscript=1" />`}
          </noscript>
          <noscript>{` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-58RR4QF"
          height="0" width="0" style="display:none;visibility:hidden"></iframe> `}</noscript>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8524907.js"></script>
          </Helmet>
          <main>{children[1]}</main>
          <Footer />
        </>
      )}
    />
    )
    }
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
