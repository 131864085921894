import React, { Component } from "react";
import { Link } from "gatsby";
import { message, AutoComplete } from "antd";
import subscribeArrow from "../../images/subscribe-arrow.svg";
import FooterLogo from "../../images/curbside-logo.png";
import ReactGA from 'react-ga'
import moment from 'moment'
import _ from "lodash";
import config from "../config";

const HOSTNAME = config.API_HOST || process.env.REACT_APP_API_HOSTNAME

message.config({
  duration: 3,
  maxCount: 3,
  rtl: true,
});

class Footerpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  // componentDidMount() {
  //   message.success("Thanks for subscribing")
  //   message.error("error message show")
  // }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  validate() {
    let errObj = {}
    const { email } = this.state
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) { // eslint-disable-line
        errObj.email = "Please enter a valid email address"
      }
    }
    if(email === "") {
      errObj.email = "Email is required"
    }
    this.setState({ err: errObj },()=>{
      this.forceUpdate()
    })
  }
  handleEventCall = ()=> {
    if(process.env.REACT_APP_GA) {
      ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Email subscriptions',
        action: 'Email subscriptions'
      })
    }
  }
  subscribe= async ()=> {
    await this.validate()
    const { err } = this.state
    if(Object.keys(err).length === 0) {
      this.handleEventCall()
      let data = {
        email: this.state.email
      }
      fetch(`${HOSTNAME}/api/quotes/subscribe`,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then((res) =>  {
        return res.json()
      }).then((data) => {
        if(data.error) {
          message.error(data.error.message)
        } else {
          message.success("Thanks for subscribing")
          this.setState({email: ''})
        }
      }).catch((err) => {
        console.log(err, 'errrr')
        throw err
      });
    }
  }

  render() {
    const { err } = this.state
    const year = moment().format('YYYY')
    return (
      <footer>
        <section className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="small-container">

                  <div className="row">
                    <div className="col-md-12">

                    <div className="left-footer">
                      <div className="row">
                        <div className="col-lg-3 col-md-12">
                          <h4 className="footer-links-title">Curbside NOW</h4>
                          <ul className="footer-links">
                            <li>
                              <Link to={`/curbside-now/new-york`}>New York City</Link>
                            </li>
                            <li>
                              <Link to={`/curbside-now/nassau`}>Nassau County</Link>
                            </li>
                            <li>
                              <Link to={`/curbside-now/westchester`}>Westchester County</Link>
                            </li>
                          </ul>
                        </div>
                        {/* 
                        <div className="col-lg-3 col-md-12">
                          <h4 className="footer-links-title">CLEANOUTS</h4>
                          <ul className="footer-links">
                            <li>
                              <Link to={``}>New York City</Link>
                            </li>
                            <li>
                              <Link to={``}>Nassau County</Link>
                            </li>
                            <li>
                              <Link to={``}>Westchester County</Link>
                            </li>
                          </ul>
                        </div> */}
                        <div className="col-lg-3 col-md-12">
                          <h4 className="footer-links-title">General</h4>
                          <ul className="footer-links">
                            <li>
                              <Link to={`about`}>About</Link>
                            </li>
                            <li>
                              <Link to={`/priceguarantee`}>Best Price Guarantee</Link>
                            </li>
                            <li>
                              <Link to={``}>For Businesses</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <h4 className="footer-links-title">RESOURCES</h4>
                          <ul className="footer-links">
                            <li>
                              <Link to={`/curbside-now/faq/`}>FAQ</Link>
                            </li>
                            <li>
                              <Link to={`/legal`}>Legal</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="right-footer d-flex justify-content-md-end">
                      {/* <div className="subscribe-wrapper">
                        <h4>Subscribe</h4>
                        <p>Sign up for expansion updates and promotions </p>
                        <div className="subscribe-from d-flex align-items-center">
                          <input
                            className="form-control"
                            placeholder="Enter email address"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange.bind(this)}
                          />
                          <button onClick={this.subscribe.bind(this)} className="btn btn-subscribe">
                            Subscribe <img src={subscribeArrow} alt="" />
                          </button>
                        </div>
                        <p className="text-danger">{err && err.email ? err.email : ""}</p>
                      </div> */}
                    </div>


                  </div>
                </div>

                  <div className="row align-items-end">
                    <div className="col-lg-6 col-md-12">
                      <div className="footer-logo-wrapper">
                        <img src={FooterLogo} alt="" />
                        <p>
                          Curbside offers an end-to-end commercial waste removal service that leverages technology to streamline home and commercial construction operations.
                        </p>
                        <p className="copyright">© Copyright {year}. Curbside. All Rights Reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    );
  }
}

export default Footerpage;
